<template>
  <div>
    <el-upload
      class="upload-demo"
      ref="upload"
      :show-file-list="false"
      action="https://upload-proxy.cn-wlcb.ufileos.com"
      :auto-upload="false"
      :file-list="fileList"
      :on-change="onchangefile"
      :multiple="true"
    >
    </el-upload>
    <el-button @click="showmsg" class="uploadbtn" size="mini" type="primary"
      >添加文件</el-button
    >
    <com-table
      class="filecomtable"
      :data="showfileList"
      :columns="filecloumns"
      tableheight="280"
      tablesize="small"
      :headercellstyle="headercellstyle"
    >
      <el-table-column
        align="center"
        header-align="center"
        slot="action"
        fixed="right"
        label="操作"
        min-width="250"
      >
        <template slot-scope="scope">
          <el-button
            @click="handleClick(scope.row)"
            type="primary"
            size="mini"
            class="deletbtn"
            >移除</el-button
          >
        </template>
      </el-table-column>
    </com-table>
  </div>
</template>
<script>
import ComTable from "../../../../components/Table/index.vue";
import { filecloumns } from "../../../../utils/tableList";
import { getsession } from "../../../../utils/auth";
import Us3Upload from "../../../../utils/us3uploadlivefile";
export default {
  props: {
    region: {
      type: String,
    },
  },
  data() {
    return {
      query: {},
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      prefilelist: [],
      fileList: [],
      showfileList: [],
      filecloumns,
      Us3Upload: null,
      less512List: [],
      more512List: [],
    };
  },
  components: { ComTable },
  methods: {
    open() {
      this.showfileList = this.showfileList.filter((item) => {
        if (item.fileprogress == "100%") {
          return false;
        } else {
          return true;
        }
      });
    },
    handleClick(row) {
      this.showfileList.forEach((item, index) => {
        if (item.uid == row.uid) {
          this.showfileList.splice(index, 1);
          this.fileList.splice(index, 1);
        }
      });
      // console.log(row);
    },
    onchangefile(file, fileList) {
      let size = file.size;
      if (size < 1024) {
        size = `${file.size}B`;
      }
      if (size / 1024 < 1024) {
        size = `${Math.round((size / 1024) * 100) / 100}KB`;
      }
      if (size / 1024 / 1024 < 1024) {
        size = `${Math.round((size / 1024 / 1024) * 100) / 100}MB`;
      }
      if (size / 1024 / 1024 / 1024 < 1024) {
        size = `${Math.round((size / 1024 / 1024 / 1024) * 100) / 100}GB`;
      }
      if (size / 1024 / 1024 / 1024 / 1024 < 1024) {
        size = `${
          Math.round((size / 1024 / 1024 / 1024 / 1024) * 100) / 100
        }TB`;
      }
      let filedata = {
        uid: file.uid,
        file: file.raw,
        filename: file.name,
        filesize: size,
        filetype: file.raw.type ? file.raw.type : "未知",
        filestate: "上传中",
        fileprogress: 0,
      };
      this.prefilelist = JSON.parse(JSON.stringify(this.fileList));
      if (this.showfileList.length) {
        try {
          this.showfileList.forEach((item, index) => {
            if (item.filename == filedata.filename) {
              throw new error();
            }
          });
          this.showfileList.push(filedata);
          this.fileList.push(filedata);
          this.submit();
        } catch (error) {
          // console.log(item);
          // console.log(filedata);
        }
      } else {
        this.showfileList.push(filedata);
        this.fileList.push(filedata);
        this.submit();
      }
    },
    submit() {
      // console.log(this.prefilelist);
      // console.log(this.fileList);
      console.log("UploadLocalFile | Us3Upload",this.region)
      if (this.Us3Upload == null) {
        this.Us3Upload = new Us3Upload(this.region);
      }
      this.fileList.forEach((item, index) => {
        if (item.file.size < 512 * 1024 * 1024) {
          console.log(item.filesize);
          if (
            JSON.stringify(this.prefilelist).indexOf(JSON.stringify(item)) == -1
          ) {
            this.less512List = [item.file];
            console.log(this.less512List);
          } else {
            this.less512List = [];
          }
        } else {
          if (
            JSON.stringify(this.prefilelist).indexOf(JSON.stringify(item)) == -1
          ) {
            this.more512List = [item.file];
          } else {
            this.more512List = [];
          }
        }
      });
      if (this.less512List.length) {
        console.log(this.less512List);
        this.commonUpload();
      }
      if (this.more512List.length) {
        console.log(this.more512List);
        this.multipartUpload();
      }
    },
    commonUpload() {
      var that = this;
      var successCallBack = function (res) {
        console.log(JSON.stringify(res));
      };
      var errorCallBack = function (res) {
        if (res.RetCode == 208412) {
          that.showfileList.forEach((item, index) => {
            if (item.uid == res.fileUid) {
              item.filestate = "文件已存在,请移除";
            }
          });
        }
        console.error("errorCallBack", res);
      };
      var progressCallBack = function (res) {
        var percent = parseFloat(res.progress * 100).toFixed(1);
        var percentComplete = percent + "%";
        // console.log(percentComplete);
        that.showfileList.forEach((item) => {
          if (item.uid == res.fileUid) {
            if (percentComplete == "100.0%") {
              item.fileprogress = "100%";
              item.filestate = "上传成功";
              that.uploadfinish(res.fileId);
            } else {
              item.fileprogress = percentComplete;
              item.filestate = "上传中";
            }
          }
        });
      };
      this.Us3Upload.batchUpload(
        this.less512List,
        successCallBack,
        errorCallBack,
        progressCallBack
      );
      this.less512List = [];
    },
    multipartUpload() {
      var successList = [];
      var currentIndex = 0;
      var that = this;
      var successCallBack = function (res) {
        // successList.push(res.file);
        // if (that.more512List.length !== successList.length) {
        //   currentIndex++;
        //   that.Us3Upload.sliceUpload(
        //     {
        //       file: that.more512List[currentIndex],
        //     },
        //     successCallBack,
        //     errorCallBack,
        //     progressCallBack
        //   );
        // }
        console.log(res);
      };
      var errorCallBack = function (res) {
        console.error("multipartUpload errorCallBack: ", res);
      };
      var progressCallBack = function (res) {
        var percent = parseFloat(res.value * 100).toFixed(1);
        var percentComplete = percent + "%";
        // console.log(percentComplete);
        that.showfileList.forEach((item) => {
          if (item.uid == res.fileUid) {
            if (percentComplete == "100.0%") {
              item.fileprogress = "100%";
              item.filestate = "上传成功";
              that.uploadfinish(res.fileId);
            } else {
              item.fileprogress = percentComplete;
              item.filestate = "上传中";
            }
          }
        });
      };
      that.Us3Upload.sliceUpload(
        {
          file: this.more512List[currentIndex],
        },
        successCallBack,
        errorCallBack,
        progressCallBack
      );
      this.more512List = [];
    },
    uploadfinish(fileId) {
      this.$store.dispatch("storage/finishuploadfile", { FileId: fileId });
    },
    showmsg() {
      this.$refs["upload"].$refs["upload-inner"].handleClick();
    },
    cleartable() {
      // (this.fileList = []((this.showfileList = []))),
      (this.less512List = []), (this.more512List = []);
    },
  },
  mounted() {
    this.query = {
      project_id: getsession("project_id"),
      // city_id: getsession("city_id"),
      product_type: getsession("product_type"),
    };
  },
};
</script>
<style lang="scss" scoped>
.uploadbtn {
  background: linear-gradient(
    135deg,
    rgb(100, 136, 252) 0%,
    rgb(56, 96, 244) 100%
  );
  box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
    rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
    rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
}
.filecomtable {
  margin-top: 20px;
}
</style>