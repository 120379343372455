<template>
  <div>
    <p class="titlelabel">
      <span>选择云手机</span>
    </p>
    <tree
      ref="treefather"
      :props="props"
      :treedata="treedata"
      @treecheck="treecheck"
    />
    <p class="titlelabel">
      <span>目录名</span>
    </p>
    <el-input
      size="small"
      placeholder="请输入目录名 /sdcard/Android/data/<APP包名>"
      v-model="uploadForm.path"
    ></el-input>
    <p class="titlelabel">
      <span>文件备注</span>
    </p>
    <el-input
      size="small"
      placeholder="请输入文件备注"
      v-model="uploadForm.remark"
    ></el-input>
    <p class="titlelabel">
      <span>上传状态</span>
    </p>
    <p :class="['success', { error: status == '上传失败' }]">
      <span>{{ status }}</span>
      <span class="progress">{{ progress }}%</span>
    </p>
  </div>
</template>
<script>
import { getsession } from "../../../../utils/auth";
import Tree from "../../../../components/Tree/index.vue";
export default {
  props: {
    region: {
      type: String,
    },
  },
  watch: {
    region(val) {
      // this.gettreedata();
    },
  },
  data() {
    return {
      props: {
        label: function (data, node) {
          if (node.isLeaf) {
            let index = data.label.indexOf("-");
            let filtlable = data.label.substring(index + 1);
            let label = `${data.uphoneName}(${filtlable})`;
            return label;
          } else {
            return data.label;
          }
        },
        children: "children",
      },
      treedata: [],
      query: {},
      uploadForm: {
        path: "/sdcard/Download",
        phoneId: "",
        remark: "",
      },
      phonelist: [],
      status: "等待中",
      progress: "0",
    };
  },
  components: { Tree },
  methods: {
    treecheck() {
      var checkedlist = this.$refs.treefather.$refs.tree.getCheckedNodes(
        false,
        true
      );
      var oldphoneList = [...this.phonelist];
      var newphoneList = [];
      checkedlist.map((item, index) => {
        if (item.isLeaf == true) {
          newphoneList.push(item);
        }
      });
      this.phonelist = this.getfinnallist(oldphoneList, newphoneList);
      console.log(this.phonelist);
      if (this.phonelist.length) {
        this.treedata.forEach((item, index) => {
          this.$set(item, "disabled", true);
          item.children.forEach((eitem, eindex) => {
            if (eitem !== this.phonelist[0]) {
              this.$set(eitem, "disabled", true);
            }
          });
        });
        this.uploadForm.phoneId = this.phonelist[0].phoneId;
      } else {
        this.treedata.forEach((item, index) => {
          this.$set(item, "disabled", false);
          item.children.forEach((eitem, eindex) => {
            if (eitem !== this.phonelist[0]) {
              this.$set(eitem, "disabled", false);
            }
          });
        });
        this.uploadForm.phoneId = "";
      }
    },
    confirmupload() {
      this.status = "正在上传...";
      var timmer = setInterval(() => {
        if (this.progress < 99) {
          this.progress++;
        }
      }, 500);
      let payload = {
        Action: "ImportUPhoneStorageFile",
        ProjectId: this.query.project_id,
        StorageRegion: this.region,
        UPhoneId: this.uploadForm.phoneId,
        FilePath: this.uploadForm.path,
        Remark: this.uploadForm.remark,
      };
      this.$store
        .dispatch("storage/importstoragefile", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.status = "已完成";
            this.progress = 100;
            clearInterval(timmer);
            this.$message.success("已上传");
            this.$emit("closeshowupload");
          } else {
            clearInterval(timmer);
            this.status = "上传失败";
            this.$message.error("上传云手机文件失败！");
          }
        })
        .catch((err) => {
          this.$message.error("上传失败");
          this.status = "上传失败";
        });
    },
    closeshowupload() {
      this.treedata = [];
      this.phonelist = [];
      this.uploadForm.phoneId = "";
      this.uploadForm.remark = "";
      this.status = "等待中";
      this.progress = 0;
    },
    getfinnallist(oldlist, newlist) {
      let relist = [];
      let inlist = [];
      let finnallist = [];
      for (let i = 0; i < oldlist.length; i++) {
        let flag = false;
        for (let j = 0; j < newlist.length; j++) {
          if (oldlist[i].phoneId == newlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          relist.push(oldlist[i]);
        }
      }
      for (let i = 0; i < newlist.length; i++) {
        let flag = false;
        for (let j = 0; j < oldlist.length; j++) {
          if (newlist[i].phoneId == oldlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          inlist.push(newlist[i]);
        }
      }
      if (inlist.length) {
        finnallist = [...oldlist, ...inlist];
      }
      if (relist.length) {
        relist.forEach((ritem, rindex) => {
          oldlist.forEach((oitem, oindex) => {
            if (ritem.phoneId == oitem.phoneId) {
              oldlist.splice(oindex, 1);
            }
          });
        });
        finnallist = oldlist;
      }
      // console.log(finnallist);
      // console.log(relist);
      // console.log(inlist);
      return finnallist;
    },
    getgrouplist() {
      let data = {
        Action: "ListBusinessGroup",
        ProjectId: this.query.project_id,
        CityId: this.query.city_id,
        // CityId: "test03",
        ProductType: this.query.product_type,
      };
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getgrouplist", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            resolve(res.Infos);
          }
        });
      });
    },
    getphonelist() {
      let data = {
        Action: "DescribeUPhone",
        ProjectId: this.query.project_id,
        CityId: this.query.city_id,
        // CityId: "test03",
        IsAll: true,
        ProductType: this.query.product_type,
      };
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getphonelist", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            resolve(res.UPhones);
          }
        });
      });
    },
    gettreedata() {
      Promise.all([this.getgrouplist(), this.getphonelist()]).then(
        ([Groups, PhoneInfos]) => {
          const filterlist =
            Groups.length &&
            Groups.filter((item, index) => {
              item["children"] = [];
              PhoneInfos.map((eitem, eindex) => {
                eitem["label"] = eitem.UPhoneId;
                eitem["value"] = eitem.UPhoneId;
                eitem["phoneId"] = eitem.UPhoneId;
                eitem["uphoneName"] = eitem.UPhoneName;
                eitem["cityId"] = eitem.CityId;
                eitem["isLeaf"] = true;
                if (eitem.Tag == item.BusinessName) {
                  item["id"] = index;
                  item["label"] = item.BusinessName;
                  item["isLeaf"] = false;
                  item["children"].push(eitem);
                }
              });
              if (item.children.length !== 0) {
                return true;
              } else {
                return false;
              }
            });
          this.treedata = filterlist;
        }
      );
    },
  },
  mounted() {
    this.query = {
      project_id: getsession("project_id"),
      city_id: getsession("city_id"),
      product_type: getsession("product_type"),
    };
    // this.gettreedata();
  },
};
</script>
<style lang="scss" scoped>
.titlelabel {
  height: 54px;
  line-height: 54px;
  font-size: 13px;
  color: rgba(56, 56, 56);
}
.success {
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  color: #409eff;
}
.error {
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  color: #f56c6c;
}
.progress {
  margin-left: 10px;
}

::v-deep .el-input {
  width: 300px;
}
</style>
