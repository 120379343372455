<template>
  <models
    :showmodel="showuploadmodel"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needfooter="needfooter"
    @confirm="confirmupload"
    @closemodel="closeshowupload"
    @open="opened"
    class="uploadmodel"
  >
    <el-tabs v-model="tabname" @tab-click="handlechange">
      <!-- <el-tab-pane label="上传云手机文件" name="first">
        <upload-phone-file @closeshowupload="closeshowupload" :region="region" ref="uploadphonefile" />
      </el-tab-pane> -->
      <el-tab-pane label="上传本地文件" name="second">
        <upload-local-file ref="uploadlocalfile" :region="region" />
      </el-tab-pane>
    </el-tabs>
  </models>
</template>
<script>
import UploadPhoneFile from "./components/UploadPhoneFile.vue";
import UploadLocalFile from "./components/UploadLocalFile.vue";
import Models from "../../../components/Models";
export default {
  props: {
    region: {
      type: String,
    },
    showuploadmodel: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
  },
  data() {
    return {
      needfooter: false,
      tabname: "second",
    };
  },
  components: { Models, UploadPhoneFile, UploadLocalFile },
  methods: {
    opened() {
      if (this.$refs.uploadlocalfile) {
        this.$refs.uploadlocalfile.open();
      }
    },
    confirmupload() {
      // this.$refs.uploadphonefile.confirmupload();
    },
    closeshowupload() {
      // this.$refs.uploadphonefile.closeshowupload();
      this.$emit("closeshowupload");
    },
    handlechange(tab, event) {
      console.log(tab.name);
      if (tab.name == "first") {
        this.needfooter = true;
      } else {
        this.needfooter = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.uploadmodel {
  ::v-deep .el-dialog {
    width: 800px;
    height: 528px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 422px;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 10px 20px;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-tabs__header {
    margin: 0;
  }
}
</style>