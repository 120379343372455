<template>
  <div>
    <el-input
      class="filterserch"
      :clearable="true"
      size="mini"
      placeholder="输入搜索文本 (支持模糊搜索)"
      v-model.trim="filterText"
    >
    </el-input>
    <el-tree
      :class="[{ eltree: fold == true }]"
      ref="tree"
      :props="props"
      :data="treedata"
      show-checkbox
      :node-key="nodekey"
      :render-content="renderContent"
      :expand-on-click-node="true"
      @node-expand="handleNodeExpand"
      @node-collapse="handelNodeCollapse"
      :filter-node-method="filterNode"
      empty-text="暂无分组"
      @check="
        (click, checked) => {
          treecheck(click, checked);
        }
      "
    >
    </el-tree>
  </div>
</template>
<script>
import formatdate from "@/utils/format";
export default {
  props: {
    props: {
      type: Object,
      default: () => {},
    },
    treedata: {
      type: Array,
      default: () => [],
    },
    defaultexpanded: {
      type: Array,
    },
    nodekey: {
      type: String,
    },
  },
  watch: {
    treedata: {
      handler: function (newval, oldval) {
        console.log("newtreedata");
        console.log(newval);
      },
      deep: true,
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      fold: false,
      filterText: "",
    };
  },
  methods: {
    renderContent(h, { node, data, store }) {
      console.log("jjjjj",node)
      if (node.isLeaf) {
        return (
          <span class="custom-tree-node">
            <span>
              <el-popover
                placement="right"
                width="200"
                trigger="hover"
                open-delay={300}
              >
                <p style="font-size:12px">
                  <span style="color:rgb(56, 96, 244);font-weight:700">
                    云手机名称 :{" "}
                  </span>
                  {node.data.uphoneName}
                </p>
                <p style="font-size:12px">
                  <span style="color:rgb(56, 96, 244);font-weight:700">
                    云手机ID :{" "}
                  </span>
                  {node.data.value}
                </p>
                  <p style="font-size:12px">
                  <span style="color:rgb(56, 96, 244);font-weight:700">
                    工作组 :{" "}
                  </span>
                  {node.data.TeamGroupId == "0" ? "工作组1"
                  :node.data.TeamGroupId == "1" ? "工作组2"
                  :node.data.TeamGroupId == "2" ? "工作组3"
                  :node.data.TeamGroupId == "3" ? "工作组4" : "工作组1"}
                </p>
                <p style="font-size:12px">
                  <span style="color:rgb(56, 96, 244);font-weight:700">
                    创建时间 :{" "}
                  </span>
                  {formatdate(node.data.createTime, "YYYY-MM-DD")}
                </p>
                <p style="font-size:12px">
                  <span style="color:rgb(56, 96, 244);font-weight:700">
                    过期时间 :{" "}
                  </span>
                  {formatdate(node.data.expireTime, "YYYY-MM-DD")}
                </p>
                <p style="font-size:12px">
                  <span style="color:rgb(56, 96, 244);font-weight:700">
                    设备状态 :{" "}
                  </span>
                  {node.data.State == "RESETTING"
                    ? "重置中"
                    : node.data.State == "RESET_FAILED"
                    ? "重置失败"
                    : node.data.State == "REBOOTING"
                    ? "重启中"
                    : node.data.State == "REBOOT_FAILED"
                    ? "重启失败"
                    : node.data.State == "CREATING"
                    ? "创建中"
                    : node.data.State == "CREATE_FAILED"
                    ? "创建失败"
                    : node.data.State == "SETTING_ROOT"
                    ? "设置Root中"
                    : node.data.State == "SET_ROOT_FAILED"
                    ? "设置Root失败"
                    : node.data.State == "SETTING_CONFIG"
                    ? "设置分辨率&DPI中"
                    : node.data.State == "SET_CONFIG_FAILED"
                    ? "设置分辨率&DPI失败"
                    : node.data.State == "SETTING_SOCKS"
                    ? "配置IP中"
                    : node.data.State == "SET_SOCKS_FAILED"
                    ? "配置IP失败"
                    : node.data.State == "SETTING_GPS"
                    ? "设置GPS中"
                    : node.data.State == "SET_GPS_FAILED"
                    ? "设置GPS失败"
                    : node.data.State == "RENEWING"
                    ? "更改机型中"
                    : node.data.State == "RENEW_FAILED"
                    ? "更改机型失败"
                    : node.data.State == "STOPPING"
                    ? "关机中"
                    : node.data.State == "STOP_FAILED"
                    ? "关机失败"
                    : node.data.State == "STOPPED"
                    ? "已关机"
                    : node.data.State == "STARTING"
                    ? "开机中"
                    : node.data.State == "START_FAILED"
                    ? "开机失败"
                    : "运行中"}
                </p>
                <el-button slot="reference" size="mini" type="text">
                  <i style="color:rgb(56, 96, 244)" class="el-icon-mobile"></i>{" "}
                  {node.label}
                  <span class={node.data.State}>
                    {node.data.State == "RESETTING"
                      ? "重置中"
                      : node.data.State == "RESET_FAILED"
                      ? "重置失败"
                      : node.data.State == "REBOOTING"
                      ? "重启中"
                      : node.data.State == "REBOOT_FAILED"
                      ? "重启失败"
                      : node.data.State == "CREATING"
                      ? "创建中"
                      : node.data.State == "CREATE_FAILED"
                      ? "创建失败"
                      : node.data.State == "SETTING_ROOT"
                      ? "设置Root中"
                      : node.data.State == "SET_ROOT_FAILED"
                      ? "设置Root失败"
                      : node.data.State == "SETTING_CONFIG"
                      ? "设置分辨率&DPI中"
                      : node.data.State == "SET_CONFIG_FAILED"
                      ? "设置分辨率&DPI失败"
                      : node.data.State == "SETTING_SOCKS"
                      ? "配置IP中"
                      : node.data.State == "SET_SOCKS_FAILED"
                      ? "配置IP失败"
                      : node.data.State == "SETTING_GPS"
                      ? "设置GPS中"
                      : node.data.State == "SET_GPS_FAILED"
                      ? "设置GPS失败"
                      : node.data.State == "RENEWING"
                      ? "更改机型中"
                      : node.data.State == "RENEW_FAILED"
                      ? "更改机型失败"
                      : node.data.State == "STOPPING"
                      ? "关机中"
                      : node.data.State == "STOP_FAILED"
                      ? "关机失败"
                      : node.data.State == "STOPPED"
                      ? "已关机"
                      : node.data.State == "STARTING"
                      ? "开机中"
                      : node.data.State == "START_FAILED"
                      ? "开机失败"
                      : "运行中"}
                  </span>
                </el-button>
              </el-popover>
            </span>
          </span>
        );
      } else {
        return (
          <span class="custom-tree-node">
            <span> {node.label}</span>
          </span>
        );
      }
    },
    treecheck(click, checked) {
      this.$emit("treecheck");
    },
    handleNodeExpand(data, node) {
      this.$emit("handleNodeExpand", data, node);
    },
    handelNodeCollapse(data, node) {
      this.$emit("handelNodeCollapse", data, node);
    },
    filterNode(value, data, node) {
      if (!value) {
        node.expanded = false;
        return true;
      }
      return this.chooseNode(value, data, node);
    },
    chooseNode(value, data, node) {
      if (data.Name.indexOf(value) !== -1 || data.Id.indexOf(value) !== -1) {
        return true;
      }
      const level = node.level;
      // 如果传入的节点本身就是一级节点就不用校验了
      if (level === 1) {
        return false;
      }
      // 先取当前节点的父节点
      let parentData = node.parent;
      // 遍历当前节点的父节点
      let index = 0;
      while (index < level - 1) {
        if (parentData.data.Name.indexOf(value) !== -1) {
          return true;
        }
        // 否则的话再往上一层做匹配
        parentData = parentData.parent;
        index++;
      }
      // 没匹配到返回false
      return false;
    },
  },
  mounted() {},
  deactivated(){
    this.filterText = ""
  }
};
</script>
<style lang="scss" scoped>
::v-deep .custom-tree-node {
  font-size: 14px;
}
.filterserch {
  margin-top: 10px;
  margin-bottom: 10px;
}
.run {
  color: rgb(56, 96, 244);
  font-weight: 700;
}
.unrun {
  color: rgb(244, 56, 103);
  font-weight: 700;
}
::v-deep .RESET_FAILED,
::v-deep .REBOOT_FAILED,
::v-deep .CREATE_FAILED,
::v-deep .SET_ROOT_FAILED,
::v-deep .RENEW_FAILED,
::v-deep .STOPPED,
::v-deep .SET_CONFIG_FAILED,
::v-deep .SET_GPS_FAILED,
::v-deep .SET_SOCKS_FAILED,
::v-deep .START_FAILED,
::v-deep .STOP_FAILED {
  color: #f56c6c;
}
::v-deep .RESETTING,
::v-deep .REBOOTING,
::v-deep .CREATING,
::v-deep .SETTING_SOCKS,
::v-deep .SETTING_ROOT,
::v-deep .RENEWING,
::v-deep .SETTING_GPS,
::v-deep .SETTING_CONFIG,
::v-deep .STARTING,
::v-deep .STOPPING {
  color: #e6a23c;
}
</style>