<template>
  <models
    :showmodel="showdeletemodel"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needfooter="needfooter"
    :closeonclickmodal="false"
    @confirm="confirmdelete"
    @closemodel="closeshowdelete"
    @open="open"
    class="deletemodel"
  >
    <div class="warningtitle">
      <p class="warningcontent">是否删除以下文件？删除后将不可恢复</p>
    </div>
    <com-table
      class="comtable"
      :data="fileList"
      :columns="columns"
      tableheight="100"
      tablesize="small"
      :headercellstyle="headercellstyle"
    />
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import ComTable from "../../../components/Table/index.vue";
import { getsession } from "../../../utils/auth";
export default {
  props: {
    showdeletemodel: {
      type: Boolean,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needfooter: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    region: {
      type: String,
    },
    row: {
      type: Object,
    },
  },
  watch: {
    region(val) {
      //   this.gettreedata();
    },
    row(val) {
      this.fileList = [val];
    },
  },
  data() {
    return {
      query: {},
      fileList: [],
      columns: [
        {
          prop: "FileName",
          label: "文件名",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "FileType",
          label: "文件类型",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            if (cellValue == "FILE_SYNC") {
              return "云手机文件";
            } else {
              return "本地文件";
            }
          },
        },
        {
          prop: "Remark",
          label: "文件备注",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            if (cellValue) {
              return cellValue;
            } else {
              return "-";
            }
          },
        },
      ],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
    };
  },
  components: { Models, ComTable },
  methods: {
    open() {},
    confirmdelete() {
      let payload = {
        FileId: this.row.FileId,
      };
      this.$store
        .dispatch("storage/deletestoragefile", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.closeshowdelete();
          } else {
            this.$message.error("删除文件失败！");
          }
        });
    },
    closeshowdelete() {
      this.$emit("closeshowdelete");
    },
  },
  mounted() {
    this.query = {
      project_id: getsession("project_id"),
      city_id: getsession("city_id"),
      product_type: getsession("product_type"),
    };
  },
};
</script>
<style lang="scss" scoped>
.deletemodel {
  ::v-deep .el-dialog {
    width: 800px;
    height: 300px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 190px;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 10px 20px;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-tabs__header {
    margin: 0;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
}
</style>