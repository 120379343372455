import store from "../store"

function UFile(bucketRegion) {

    // 存储空间域名。既可以在这里配置，也可以在实例化时传参配置。
    // 例如 bucketUrl = "https://example-name.cn-bj.ufileos.com/"
    // this.bucketUrl = bucketUrl || "";

    //存储空间区域
    this.bucketRegion = bucketRegion || "";

    this.createAjax = function (argument) {
        var xmlhttp = {};
        if (window.XMLHttpRequest) {
            xmlhttp = new XMLHttpRequest();
        } else {
            xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
        }

        // 发送二进制数据
        if (!XMLHttpRequest.prototype.sendAsBinary) {
            XMLHttpRequest.prototype.sendAsBinary = function (datastr) {
                function byteValue(x) {
                    return x.charCodeAt(0) & 0xff;
                }
                var ords = Array.prototype.map.call(datastr, byteValue);
                var ui8a = new Uint8Array(ords);
                this.send(ui8a.buffer);
            }
        }
        return xmlhttp;
    };

    this.getBucketUrl = function () {
        var bucketUrl = this.bucketUrl;

        // 如果不是以"/"结尾，则自动补上
        if (bucketUrl.charAt(bucketUrl.length - 1) !== "/") {
            bucketUrl += "/";
        }
        return bucketUrl;
    }

    // 重命名文件
    this.getFileName = function (file, fileRename) {
        var fileName = file.name
        if (fileRename && fileRename != "") {
            fileName = fileRename
        }
        return fileName

    }
    this.posttoken = ""
    this.puttoken = ""
    this.uploadingtoken = "",
        this.uploadingurl = "",
        this.fileId = ""

}

UFile.prototype.contentMd5 = ""; // 文件的md5
UFile.prototype.slice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
UFile.prototype.sliceSize = 4 * 1024 * 1024; // 分片大小为4M
UFile.prototype.getExpired = function (second) {
    return Date.parse(new Date()) / 1000 + (second || 600);
}

// 获取文件管理签名token 
UFile.prototype.getUFileToken = function (options, success, error) {
    let data = {
        // method: options.method,
        StorageRegion: this.bucketRegion,
        FileName: options.fileName,
        FileSize: options.fileSize,
        ContentType: options.fileType,
    }
    store.dispatch("storage/filetoken", {
        ...data
    }).then((res) => {
        if (res.BackendRetCode == 101) {
            store.dispatch("storage/deletestoragefile", {
                FileId: res.FileId
            })
            return
        }
        if (res.RetCode == 0) {
            this.fileId = res.FileId
            this.puttoken = res.PutToken
            this.posttoken = res.PostToken
            this.uploadingurl = res.BucketUrl
            success()
        }
        if (res.RetCode == 208412) {
            error({
                RetCode: 208412,
                msg: res.Message,
                file: options.file,
                fileUid: options.fileUid,
                fileName: options.fileName
            });
        }

    }).catch((err) => {
        error()
    })
}


// 获取文件管理签名token 
UFile.prototype.UFileToken = function (options, success, error) {
    let data = {
        method: options.method,
        StorageRegion: this.bucketRegion,
        FileName: options.fileName,
        FileSize: options.fileSize,
        ContentType: options.fileType,
    }
    store.dispatch("storage/getfiletoken", {
        ...data
    }).then((res) => {
        if (res.RetCode == 0) {
            success(res.Token, res.BucketUrl)
        }
        if (res.RetCode == 208412) {
            error({
                RetCode: 208412,
                msg: res.Message,
                file: options.file,
                fileUid: options.fileUid,
                fileName: options.fileName
            });
        }

    }).catch((err) => {
        error()
    })
}

// 获取文件列表
UFile.prototype.getFileList = function (options, success, error) {

    var that = this;
    var method = "GET";
    var prefix = options.prefix || that.PREFIX;
    var marker = options.marker || "";
    var limit = options.limit || 20;

    var requestToken = {
        method: method
    };

    this.getUFileToken(requestToken, function (token) {

        var ajax = that.createAjax();
        var url = that.getBucketUrl() + "?list" +
            "&prefix=" + prefix +
            "&marker=" + marker +
            "&limit=" + limit;
        ajax.open(method, url, true);
        ajax.setRequestHeader("Authorization", token);

        var onreadystatechange = function () {
            if (ajax.readyState == 4) {
                if (ajax.status == 200) {
                    success(JSON.parse(ajax.response));
                } else {
                    error(ajax.responseText);
                }
            }
        };

        ajax.onreadystatechange = onreadystatechange;
        ajax.send();

    }, error);
}

// 查看文件信息
UFile.prototype.getFileDetail = function (fileName, success, error) {

    var that = this;
    var method = "HEAD";
    var requestToken = {
        method: method,
        fileName: fileName
    };

    this.getUFileToken(requestToken, function (token) {

        var ajax = that.createAjax();
        var url = that.getBucketUrl() + encodeURIComponent(fileName);
        ajax.open(method, url, true);
        ajax.setRequestHeader("Authorization", token);

        var onreadystatechange = function () {
            if (ajax.readyState === 4) {
                if (ajax.status === 200) {

                    var eTag = ajax.getResponseHeader("ETag");
                    var successRes = {
                        contentType: ajax.getResponseHeader("Content-Type"),
                        eTag: eTag.substring(1, eTag.length - 1),
                        status: ajax.status,
                        response: ajax.response
                    };
                    success(successRes);

                } else {
                    error(ajax.responseText);
                }
            }
        };

        ajax.onreadystatechange = onreadystatechange;
        ajax.send();

    }, error);
}

// 普通上传
UFile.prototype.uploadFile = function (options, success, error, progress) {
    var that = this;
    var method = "PUT";
    var file = options.file || {};
    //var fileRename = options.fileRename;
    //var fileName = this.addPrefix(this.getFileName(file, fileRename));
    //var putPolicy = options.putPolicy;
    var fileName = file.name;
    var fileType = file.type;
    var fileUid = file.uid
    var fileSize = file.size
    var size = `${Math.round((fileSize / 1024 / 1024 / 1024) * 100) / 100}`;
    console.log(size)
    var requestToken = {
        file: file,
        fileName: fileName,
        fileType: fileType,
        fileSize: size,
        fileUid: fileUid,
        method: method
    };

    this.getUFileToken(requestToken, function () {
        var fileId = that.fileId
        var ajax = that.createAjax();
        var url = that.uploadingurl + that.fileId;
        // var url = "https://uphone-storage-test.cn-wlcb.ufileos.com/" + fileName

        ajax.open(method, url, true);
        ajax.setRequestHeader("Authorization", that.puttoken);
        // ajax.setRequestHeader("Authorization", "UCloud 4eXwD7wpcsSKixM0A5Gg0k7ifIobLMTYr:+yHnCxyu7H5ZNdirp8lg6FE7HwE=");
        //ajax.setRequestHeader("Content-MD5", that.contentMd5);
        ajax.setRequestHeader("Content-Type", fileType);

        var onreadystatechange = function () {
            if (ajax.readyState === 4) {
                if (ajax.status === 200) {
                    success({
                        msg: ajax.responseText,
                        file: file,
                        fileUid: fileUid,
                        fileName: fileName
                    });
                } else {
                    error({
                        msg: ajax.responseText,
                        file: file,
                        fileUid: fileUid,
                        fileName: fileName
                    });
                }
            }
        };
        var onprogress = function (event) {
            if (event.lengthComputable) {
                progress({
                    progress: event.loaded / event.total,
                    fileUid: fileUid,
                    fileName: fileName,
                    fileId: fileId
                });
            }
        };

        ajax.onreadystatechange = onreadystatechange;
        ajax.upload.onprogress = onprogress;
        ajax.send(file);

    }, error);
}

// 批量上传
UFile.prototype.batchUpload = function (fileList, success, error, progress) {
    var self = this;
    var successList = [];
    var errorList = [];
    var currentIndex = 0;

    if (fileList.length == 0) {
        console.warn("上传至云手机列表为空")
        return;
    }

    var successCallBack = function (res) {
        successList.push(res.file);
        success(res);
        if (successList.length == fileList.length) {
            // success(successList);
        } else {
            currentIndex++;
            self.uploadFile({
                file: fileList[currentIndex]
            }, successCallBack, errorCallBack, progressCallBack);
        }
    };

    var errorCallBack = function (res) {
        errorList.push(res.file);
        error(res)
        // if ((successList.length + errorList.length) == fileList.length) {
        //     error({
        //         errorList: errorList,
        //         successList: successList
        //     });
        // }
    };
    var progressCallBack = function (res) {
        progress(res)
    };

    progress(0);
    self.uploadFile({
        file: fileList[currentIndex]
    }, successCallBack, errorCallBack, progressCallBack);
}

// 分片上传（外部调用）
UFile.prototype.sliceUpload = function (options, success, error, progress) {
    var that = this;
    var file = options.file || {};
    var filesize = file.size;
    var fileName = file.name;
    var fileUid = file.uid

    // var chunks = Math.ceil(file.size / this.sliceSize);
    var currentChunk = 0;


    var method = "PUT";
    var fileSize = filesize
    var fileType = file.type;
    var size = `${Math.round((fileSize / 1024 / 1024 / 1024) * 100) / 100}`;
    console.log(size)
    var requestToken = {
        method: method,
        file: file,
        fileSize: "0",
        fileType: fileType,
        fileName: fileName,
        md5Required: false
    }

    // 初始化分片
    this.initMultipartUpload(function (intResponse, fileId, uploadingurl, posttoken, puttoken) {
        var keyName = intResponse.Key;
        var uploadId = intResponse.UploadId;
        var sliceSize = intResponse.BlkSize;
        var chunks = Math.ceil(file.size / sliceSize);
        var partNumber = 0;
        var requestSuccess = 0; // 各分片请求成功数
        var eTags = "";

        // 每块文件读取完毕之后的处理
        var fileReader = new FileReader();
        fileReader.onload = function (e) {
            currentChunk++
            // 如果文件处理完，调用完成分片；否则还有分片则继续处理
            if (currentChunk < chunks) {
                loadNext();
            }
        };



        loadNext();
        // var ajax = that.createAjax();
        // // var url = BucketUrl + fileName;
        // var url = BucketUrl + encodeURIComponent(keyName) +
        //     "?uploadId=" + uploadId +
        //     "&partNumber=" + partNumber;
        // ajax.open(method, url, true);
        // ajax.setRequestHeader("Authorization", token);
        // ajax.setRequestHeader("Content-Type", file.type);

        // var onreadystatechange = function () {
        //     if (ajax.readyState === 4) {
        //         if (ajax.status === 200) {
        //             var eTag = ajax.getResponseHeader("ETag");
        //             var result = {
        //                 eTag: eTag.substring(1, eTag.length - 1),
        //                 response: ajax.response
        //             };
        //             success(result);
        //         } else {
        //             error(ajax.responseText);
        //         }
        //     }
        // };
        // ajax.onreadystatechange = onreadystatechange;
        // ajax.send(file);



        // 处理单片文件的上传
        function loadNext() {
            var start = currentChunk * sliceSize;
            var end = start + sliceSize >= file.size ? file.size : start + sliceSize;
            var currentFile = that.slice.call(file, start, end, file.type);
            currentFile.name = file.name;

            // 上传各分片
            that.multipartUploading(function (multipartResponse) {
                requestSuccess++;
                if (eTags == "") {
                    eTags = multipartResponse.eTag;
                } else {
                    eTags = eTags + "," + multipartResponse.eTag;
                }

                var sliceCompleted = {
                    status: "uploading",
                    value: requestSuccess / chunks,
                    fileUid: fileUid,
                    fileId: fileId
                };
                progress(sliceCompleted); // 上传各分片进度
                // fileReader.readAsArrayBuffer(currentFile);
                if (requestSuccess == 1000) {
                    fileReader.readAsArrayBuffer(currentFile);
                }
                if (requestSuccess == 2000) {
                    fileReader.readAsArrayBuffer(currentFile);
                }
                if (requestSuccess == 3000) {
                    fileReader.readAsArrayBuffer(currentFile);
                }
                if (requestSuccess == 4000) {
                    fileReader.readAsArrayBuffer(currentFile);
                }
                if (requestSuccess == chunks) {
                    // 完成分片
                    that.multipartUploaded(function (uploaded) {
                        success(uploaded);

                    }, error, progress, keyName, uploadId, file, eTags, fileUid, fileId, uploadingurl, posttoken, puttoken);
                }
            }, error, keyName, uploadId, partNumber, currentFile, filesize, fileId, uploadingurl, posttoken, puttoken);

            partNumber++;
            console.log(currentChunk)
            if (currentChunk < 1000) {
                fileReader.readAsArrayBuffer(currentFile);
            }
            // if (currentChunk > 1000) {
            //     fileReader.readAsArrayBuffer(currentFile);
            // }
            if (currentChunk > 1000 && currentChunk < 2000) {
                fileReader.readAsArrayBuffer(currentFile);
            }
            if (currentChunk > 2000 && currentChunk < 3000) {
                fileReader.readAsArrayBuffer(currentFile);
            }
            if (currentChunk > 3000 && currentChunk < 4000) {
                fileReader.readAsArrayBuffer(currentFile);
            }
            // console.log(requestSuccess)
            // if(currentChunk < 1000)

        }



    }, error, progress, file, fileName);
}

// 初始化上传（内部调用）
UFile.prototype.initMultipartUpload = function (success, error, progress, file, fileName) {
    var that = this;
    var method = "POST";
    var contentType = file.type || ""; // application/octet-stream
    var fileType = file.type
    var fileSize = file.size
    var size = `${Math.round((fileSize / 1024 / 1024 / 1024) * 100) / 100}`;
    var requestToken = {
        method: method,
        file: file,
        fileSize: size,
        fileType: fileType,
        fileName: fileName,
        md5Required: false
    }
    console.log(JSON.stringify(requestToken));

    this.getUFileToken(requestToken, function (token, BucketUrl) {
        var uploadingurl = that.uploadingurl
        var fileId = that.fileId
        var posttoken = that.posttoken
        var puttoken = that.puttoken

        var ajax = that.createAjax();
        var url = uploadingurl + fileId + "?uploads";
        console.log(url)
        ajax.open(method, url, true);
        ajax.setRequestHeader("Authorization", posttoken);
        ajax.setRequestHeader("Content-Type", contentType);

        var onreadystatechange = function () {
            if (ajax.readyState === 4) {
                if (ajax.status === 200) {
                    console.log(JSON.parse(ajax.response));
                    success(JSON.parse(ajax.response), fileId, uploadingurl, posttoken, puttoken);
                } else {
                    error(ajax.responseText);
                }
            }
        };
        var onprogress = function (event) {
            if (event.lengthComputable) {
                var result = {
                    status: "init",
                    value: event.loaded / event.total
                };
                progress(result);
            }
        };

        ajax.onreadystatechange = onreadystatechange;
        ajax.upload.onprogress = onprogress;
        ajax.send();

    }, error);
}

// 上传分片（内部调用）
UFile.prototype.multipartUploading = function (success, error, keyName, uploadId, partNumber, file, filesize, fileId, uploadingurl, posttoken, puttoken) {
    // console.log(file)
    var that = this;
    var method = "PUT";
    var fileSize = filesize
    var fileType = file.type;
    var fileName = keyName
    var size = `${Math.round((fileSize / 1024 / 1024 / 1024) * 100) / 100}`;
    // console.log(size)
    var requestToken = {
        method: method,
        file: file,
        fileSize: size,
        fileType: fileType,
        fileName: keyName,
        md5Required: false
    }
    var ajax = that.createAjax();
    // var url = BucketUrl + fileName;
    var url = uploadingurl + fileId +
        "?uploadId=" + uploadId +
        "&partNumber=" + partNumber;
    ajax.open(method, url, true);
    ajax.setRequestHeader("Authorization", puttoken);
    ajax.setRequestHeader("Content-Type", file.type);

    var onreadystatechange = function () {
        if (ajax.readyState === 4) {
            if (ajax.status === 200) {
                var eTag = ajax.getResponseHeader("ETag");
                var result = {
                    eTag: eTag.substring(1, eTag.length - 1),
                    response: ajax.response
                };
                success(result);
            } else {
                error(ajax.responseText);
            }
        }
    };
    ajax.onreadystatechange = onreadystatechange;
    ajax.send(file);

}

// 完成分片（内部调用）
UFile.prototype.multipartUploaded = function (success, error, progress, keyName, uploadId, file, eTags, fileUid, fileId, uploadingurl, posttoken, puttoken) {
    var that = this;
    var method = "POST";
    var contentType = file.type;
    var fileSize = file.size
    var fileType = file.type;
    var size = `${Math.round((fileSize / 1024 / 1024 / 1024) * 100) / 100}`;
    var requestToken = {
        method: method,
        file: file,
        fileSize: size,
        fileType: fileType,
        fileName: keyName,
        md5Required: false,
        contentType: contentType
    };

    var ajax = that.createAjax();
    var url = uploadingurl + fileId + "?uploadId=" + uploadId;

    ajax.open(method, url, true);
    ajax.setRequestHeader("Authorization", posttoken);
    ajax.setRequestHeader("Content-Type", contentType);

    var onreadystatechange = function () {
        if (ajax.readyState === 4) {
            if (ajax.status === 200) {
                success({
                    responseText: ajax.responseText,
                    file: file,
                    fileName: keyName,
                    fileUid: fileUid
                });
            } else {
                error(ajax.responseText);
            }
        }
    };
    var onprogress = function (event) {
        if (event.lengthComputable) {
            var result = {
                status: "uploaded",
                value: event.loaded / event.total
            };
            progress(result);
        }
    };

    ajax.onreadystatechange = onreadystatechange;
    ajax.upload.onprogress = onprogress;
    ajax.send(eTags);

}

// 表单上传
UFile.prototype.formUpload = function (options, success, error) {
    var that = this;
    var method = "POST";
    var file = options.file || {};
    var fileRename = options.fileRename;
    var fileName = this.addPrefix(this.getFileName(file, fileRename));
    var putPolicy = options.putPolicy

    var requestToken = {
        method: method,
        file: file,
        fileName: fileName,
        putPolicy: putPolicy
    };

    this.getUFileToken(requestToken, function (token) {
        var ajax = that.createAjax();
        var url = that.getBucketUrl() + encodeURIComponent(fileName);
        var reader = new FileReader();

        // FileReader API是异步的,我们需要把读取到的内容存储下来
        reader.addEventListener("load", function () {

            var byteArray = new Uint8Array(reader.result);
            var fileBinary = "";

            for (var i = 0; i < byteArray.length; i++) {
                fileBinary += String.fromCharCode(byteArray[i]);
            }

            file.binary = fileBinary;

            // 虚拟出Blob格式的fileName
            var blobFileName = new Blob([fileName]);
            // Blob格式的fileName的FileReader
            var readerFileName = new FileReader();

            // 取得fileName的特定编码格式
            readerFileName.addEventListener("load", function () {
                var innerByteArray = new Uint8Array(readerFileName.result);
                var innerFileBinary = "";

                for (var i = 0; i < innerByteArray.length; i++) {
                    innerFileBinary += String.fromCharCode(innerByteArray[i]);
                }

                var reFileName = innerFileBinary;

                var boundary = "----UCloudPOSTFormBoundary";
                var data = "--" + boundary + "\r\n" +
                    "Content-Disposition: form-data; " + 'name="FileName"' + "\r\n" + "\r\n" +
                    reFileName + "\r\n" +
                    "--" + boundary + "\r\n" +
                    "Content-Disposition: form-data; " + 'name="Authorization"' + "\r\n" + "\r\n" +
                    token + "\r\n" +
                    "--" + boundary + "\r\n" +
                    "Content-Disposition: form-data; " + 'name="file"; ' + 'filename="' + reFileName + '"' + "\r\n" +
                    "Content-Type: " + file.type + "\r\n" + "\r\n" +
                    file.binary + "\r\n" +
                    "--" + boundary + "--" + "\r\n";

                ajax.open(method, url, true);
                ajax.setRequestHeader("Content-MD5", that.contentMd5);
                ajax.setRequestHeader("Content-Type", "multipart/form-data; boundary=" + boundary);

                var onreadystatechange = function () {
                    if (ajax.readyState == 4) {
                        if (ajax.status == 200) {
                            success(ajax.response);
                        } else {
                            error(ajax.response);
                        }
                    }
                };
                ajax.onreadystatechange = onreadystatechange;
                ajax.sendAsBinary(data);

            });

            // 读取Blob格式的fileName
            if (blobFileName) {
                readerFileName.readAsArrayBuffer(blobFileName);
            }
        });

        // 读取文件的二进制内容
        if (file) {
            reader.readAsArrayBuffer(file);
        }

    }, error);
}

// 秒传文件
UFile.prototype.hitUpload = function (file, success, error) {
    var that = this;
    var method = "POST";

    this.getFileDetail(this.addPrefix(file.name), function (fileDetail) {
        var requestToken = {
            method: method,
            file: file,
            md5Required: false
        };

        that.getUFileToken(requestToken, function (token) {

            var ajax = that.createAjax();
            var url = that.getBucketUrl() +
                "uploadhit?Hash=" + fileDetail.eTag +
                "&FileName=" + encodeURIComponent(that.addPrefix(file.name)) +
                "&FileSize=" + file.size;
            ajax.open(method, url, true);
            ajax.setRequestHeader("Authorization", token);
            ajax.setRequestHeader("Content-Type", file.type);

            var onreadystatechange = function () {
                if (ajax.readyState === 4) {
                    if (ajax.status === 200) {
                        success(ajax.responseText);
                    } else {
                        error(ajax.responseText);
                    }
                }
            };

            ajax.onreadystatechange = onreadystatechange;
            ajax.send(file);

        }, error);

    }, error);
}

// 下载文件
UFile.prototype.downloadFile = function (fileName, success, error, progress) {
    var that = this;
    var method = "GET";
    var requestToken = {
        method: method,
        fileName: fileName
    };

    this.getUFileToken(requestToken, function (token) {

        var ajax = that.createAjax();
        var url = that.getBucketUrl() + encodeURIComponent(fileName);
        ajax.open(method, url, true);
        ajax.responseType = "blob";
        ajax.setRequestHeader("Authorization", token);

        var onreadystatechange = function () {
            if (ajax.readyState == 4) {
                if (ajax.status == 200) {
                    var aTag = document.createElement("a");
                    var blob = ajax.response;

                    aTag.download = fileName;
                    aTag.href = URL.createObjectURL(blob);
                    aTag.click();
                    URL.revokeObjectURL(blob);
                    success(ajax.response);

                } else {
                    error(ajax.response)
                }
            }
        };
        var onprogress = function (event) {
            if (event.lengthComputable) {
                progress(event.loaded / event.total);
            }
        };

        ajax.onreadystatechange = onreadystatechange;
        ajax.onprogress = onprogress;
        ajax.send();

    }, error);
}

// 删除文件
UFile.prototype.deleteFile = function (fileName, success, error) {
    var that = this;
    var method = "DELETE";
    var requestToken = {
        method: method,
        fileName: fileName
    };

    this.getUFileToken(requestToken, function (token) {

        var ajax = that.createAjax();
        var url = that.getBucketUrl() + encodeURIComponent(fileName);
        ajax.open(method, url, true);
        ajax.setRequestHeader("Authorization", token);

        var onreadystatechange = function () {
            if (ajax.readyState == 4) {
                if (ajax.status == 204) {
                    success({
                        msg: ajax.responseText,
                        file: fileName
                    });
                } else {
                    error({
                        msg: ajax.responseText,
                        file: fileName
                    });
                }
            }
        };

        ajax.onreadystatechange = onreadystatechange;
        ajax.send();

    }, error);
}

// 批量删除
UFile.prototype.batchDelete = function (fileList, success, error) {
    var self = this;
    var successList = [];
    var errorList = [];

    if (fileList.length == 0) {
        console.warn("删除列表为空")
        return;
    }

    for (var i = 0; i < fileList.length; i++) {
        var successCallBack = function (res) {
            successList.push(res.file);

            if (successList.length == fileList.length) {
                success(successList)
            }

        };

        var errorCallBack = function (res) {
            errorList.push(res.file);

            if ((successList.length + errorList.length) == fileList) {
                error({
                    successList: successList,
                    errorList: errorList
                })
            }
        }

        this.deleteFile(fileList[i], successCallBack, errorCallBack);
    }

}

export default UFile;